.footer__wrap {
    width: 100%;
    height: max-content;
    background-color: #F3F3F3;;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 75px;
    padding-top: 50px;
    gap: 50px;
}
.footer__wrap >p{
    text-align: center;
    font-size: 14px;
}
.footer__inner {
    width: 80%;
    height: max-content;
    display: flex;
    justify-content: space-between;
}

.footer__wrap a {
    text-decoration: none;
    cursor: pointer;
    color: #000;
}

.footer__buttons__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__phone__button {
    width: max-content;
    border-radius: var(--button-border-radius);
    border: solid 1px var(--blue);
    color: var(--blue);
    background: none;
    padding: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer__phone__button span,.footer__phone__button a {
    font-family: 'Kyiv Region';
    max-height: 18px;
    text-decoration: none;
    color: var(--blue);
}
.footer__icon__wrap {
    max-width: 24px;
    max-height: 24px;
}

.footer__support__button {
    height: max-content;
    border-radius: var(--button-border-radius);
    background-color: var(--yellow);
    padding: 17px 22px;
    border: none;
    color: var(--blue);
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
}

.footer__links__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__links__wrap p {
    margin: 0;
}
.copyright a {
    color: var(--blue);
    
}
@media screen and (max-width:1000px){
    .footer__wrap{
        gap: 0;
    }
    .footer__inner{
        width: max-content;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap: 50px;
    }
    .footer__links__wrap, .footer__buttons__wrap{
        align-items: center;
    }
    .img__wrap{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@font-face {
  font-family: "Kyiv Region";
  src: url('../public/fonts/KYIVREGION-REGULAR.TTF')
}
@font-face {
  font-family: "Latoweb";
  src: url('../public/fonts/LATOWEB-REGULAR.TTF')
}
@font-face {
  font-family: "Inter";
  src: url('../public/fonts/Inter-Regular.ttf')
}
@font-face {
  font-family: "Montserrat";
  src: url('../public/fonts/Montserrat-Regular.ttf')
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Kyiv Region';
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --yellow:#F3C623;
  --blue:#30358C;
  --picture-border-radius:10px;
  --card-border-radius:10px;
  --button-border-radius:10px;
}
p,span,h3{
  font-family: 'Latoweb';
}
button{
  cursor: pointer;
  font-family: 'Kyiv Region';
  font-size: 16px;
}
.eng *{
  font-family: 'Montserrat'!important;
}
::-webkit-scrollbar{
  width: 6px;
  border-radius: 10px;
  background-color: lightgray;
}
::-webkit-scrollbar-thumb{
  background-color: #30358C;
  border-radius: 10px;
}

.form{
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    max-height: 80vh;
    overflow-y: scroll;
    max-width: 90vw;

}
.form-inner{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title {
    font-size: 14px;
    text-transform: uppercase;
}
.close_btn {
    width: 20px;
    height: 20px;
    position: relative;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.close_btn span {
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    height: 2px;
    background-color: #000;
}
.close_btn .f {
    transform: rotate(45deg);
}
.close_btn .s {
    transform: rotate(-45deg);
}
.body{
    padding-right: 10px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
.line{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}
.item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.tem_title{
    font-size: 14px;
    font-weight: 400;
}
.item_description{
    font-size: 14px;
    font-weight: 600;
    font-family: 'Latoweb';
}
.item_description:hover{
    cursor: pointer;
}
.line_title {
    font-size: 18px;
    font-weight: 600;
}
.footer{}
.drop__container {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    border: solid 2px var(--blue);
    border-radius: 10px;
}
.drop__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
    cursor: pointer;
}
.drop__title:hover .drop__arrow{
    background-color: var(--yellow);
    border-radius: 50%;
}

.drop__description {
    font-family: 'Inter';
    font-size: 16px;
    padding-top: 0px;
    height: 0;
    overflow: hidden;
    transition: all .3s;
    padding-left: 10px;
    box-sizing: border-box;
}
.drop__arrow {
    width: 25px;
    height: 25px;
    position: relative;
    transition: all .3s;
    cursor: pointer;
}
.drop__arrow img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s;

}
.drop__arrow.active {
    transform: translateY(0%) rotate(180deg);
}
.drop__description.active {
    height: auto;
    padding-top: 10px;

}
.drop__description {
    display: grid;
    grid-template-columns: 5px 1fr;
    gap: 5px;

}
.drop__line {
    background-color: var(--blue);
    border-radius: 10px;
}
.monoImg {
    width: 30px;
}
.drop__title__elem {
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: uppercase;
}
.drop__title__elem a {
    color: #000;
    
}
.paypal_link {
    display: block;
    text-decoration: none;
    color: var(--blue);
    background-color: var(--yellow);
    padding: 5px 15px;
    border-radius: 8px;
}
.grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
@media screen and (max-width:600px) {
    .form {
        max-width: 80vw;
    }
    .item {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3px;
    }
}
.values__wrap{
    width: 100%;
    height: max-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.values__inner{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
}
.values__list{
    border-radius: var(--card-border-radius);
    border: solid 2px var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    padding: 60px;
    gap: 10px;
}
.img__wrap{
    display: flex;
    align-items: center;
}
.img__wrap img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}
.values__list p{
    font-size: 24px;
    font-family: 'Kyiv Region';
    margin: 0;
}
.title {
    text-transform: uppercase;
    font-weight: normal;
}
.values__list__multi{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}
.values__multi{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    gap: 10px;
}
@media screen and (max-width:850px){
    .values__inner{
        grid-template-columns: none;
        grid-template-rows: max-content 1fr;
    }
    .values__list__multi{
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr;
    }
    

}
@media screen and (max-width:1000px){
.img__wrap{
    height: 100%;
    display: flex;
    align-items: center;
}
.values__inner:has(.values__multi) {
    grid-template-columns: none;
    grid-template-rows: max-content 1fr;
}
}
@media screen and (max-width:500px){
    .values__list{
        padding: 30px;
        gap: 38px;
    }
    .values__inner{
        grid-template-rows: 0.5fr 1fr;
    }
}
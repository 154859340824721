.header__wrap {
    width: 100%;
    height: max-content;
    padding: 25px 0px;
}

.header__inner {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__panel {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    gap: 25px;
}

.header__phone__button {
    border-radius: var(--button-border-radius);
    border: solid 1px var(--blue);
    color: var(--blue);
    background: none;
    padding: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.header__phone__button span, .header__phone__button a{
    font-family: 'Kyiv Region';
    max-height: 18px;
    color: var(--blue);
    text-decoration: none;
}
.header__icon__wrap{
    max-width: 24px;
    max-height: 24px;
}
.header__support__button {
    border-radius: var(--button-border-radius);
    background-color: var(--yellow);
    padding: 17px 22px;
    border: none;
    color: var(--blue);
    text-transform: uppercase;
}

.lang__wrap {
    width: max-content;
    border-radius: var(--button-border-radius);
    border: solid 1px var(--blue);
    display: flex;
    height: max-content;
    gap: 5px;
    color: var(--blue);
    padding: 5px 8px;
    text-transform: uppercase;

}


.lang__line {
    width: 1.5px;
    background-color: var(--blue);
}
.lang__span{
    color: var(--blue);
    text-decoration: none;
}
.lang__span:hover{
    transition: all .3s;
    cursor: pointer;
}
.active {
    font-weight: bolder;
    color: var(--blue);
    text-decoration: none;

}

@media screen and (max-width:800px){
    .header__phone__button, .header__panel > .lang__wrap{
        display: none;
    }
    .header__support__button{
        font-size: 15px;
    }
    .header__inner{
        gap: 15px;
    }
    .header__panel{
        gap: 15px;
    }
}
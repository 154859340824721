.form__wrap{
    width: 506px;
    height: 475px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    top: 175px;
    left: 50px;
    background-color: #fff;
    display: none;
}
@media screen and (max-width:1000px){
    .form__wrap{
        display: none;
    }
}
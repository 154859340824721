.title {
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
}

.slider__wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.slider__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.slider__dots {
    display: flex;
    gap: 5px;
}

.slider {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    width: 100%;
}

.slider__content {
    transition: all .3s;
    height: max-content;
    display: flex;
}

.slider_content_wrap {
    width: 100%;
    overflow: hidden;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #5a5fc5;
    cursor: pointer;
}

.dot:hover {
    /* transform: scale(1.25); */
    background-color: var(--blue);
    transition: all .3s;
}

.dot__active {
    background-color: var(--blue);
}

.slider__card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 33.3%;
    transition: all .3s;
}

.slider__card p {
    margin: 0;
}

.bold {
    font-weight: bold;
}

.arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.img__wrap img {
    max-width: 200px;
    max-height: 175px;
}
.link__to__site {
    display: block;
}

@media screen and (max-width:600px) {
    .slider__card {
        transform: scale(0.8);
    }
}
.title {
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
}

.slider__wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.slider__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.slider__dots {
    display: flex;
    gap: 5px;
}

.slider {
display: grid;
grid-template-columns: 30px 1fr 30px;
width: 100%;
}

.slider__content {
    transition: all .3s;
    height: max-content;
    display: flex;
}
.slider_content_wrap{
    width: 100%;
    overflow: hidden;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #5a5fc5;
    cursor: pointer;
}
.dot:hover{
    /* transform: scale(1.25); */
    background-color: var(--blue);
    transition: all .3s;
}
.dot__active{
    background-color: var(--blue);
}
.slider__card {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 20%;
    transition: all .3s;
    text-align: center;
}
/* .img__wrap img{
    width: 180px;
    height: 180px;
} */
.slider__card p {
    margin: 0;
}

.bold {
    font-weight: bold;
}

.arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.img__wrap img {
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
}

.scale08 {
    transform: scale(0.8);
}

.scale09 {
    transform: scale(0.9);
}
@media screen and (max-width:600px){
    .slider__card{
        transform: scale(0.8);
    }
}